@import (reference) '~bootstrap/less/bootstrap.less';

@import (reference) '~@maternity/kerbin/app/css/style.less';
@import 'highlights';
@import 'flowsheet';

// Delete once we've upgraded to bootstrap 3.2
.tooltip {
  z-index:1070;
}

// Used on the labs flowsheet and the alerts panel.
.ehr-tooltip {
  position: absolute;
  margin:0 20px 0 6px;
  background: #FFF;
  padding: 12px 15px;
  text-align: left;
  z-index: 1;
  border: 1px solid #7a7a7a;
  box-shadow: 2px 2px 2px #999;
  /* For IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#666666')";
  /* For IE 5.5 - 7 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#666666');

  .note-meta-titles {
    font-weight: bold
  }

  p {
    margin:7px 0 -7px
  }

  .note-meta-assessment {
    margin: 18px 0 15px;
    font-style: italic;
  }

}

.indented {
  margin-left:18px;
}

// Used for IMO search results
.imo-search-cell {
  width: 100px;
  display: inline-block;
}

.imo-icon-cell {
  width: 25px;
  display: inline-block;
}

// Mask values of sensitive fields unless they have focus
.toggle-mask.masked {
  color: transparent;
  #gradient > .striped(#eee);
  background-size: 40px 40px;

  // Restore properties on focus
  &:focus {
    color: inherit;
    background-image: none;
  }
}
