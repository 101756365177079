
.modal-dialog {
  width:740px;

  @media @sm-max {
    width: 95%;
  }
}

.modal-header {
  padding:11px 15px 9px;

  .close {
    margin: 0;
  }
}


.modal-header {
  // TODO: De-duplicate
  .close {
    float: right;
    font-size: (@font-size-base * 1.5);
    font-weight: @close-font-weight;
    line-height: 1;
    color: @mn-teal;
    .opacity(.9);
    padding: 7px;
    position: relative;
    top: -4px;
    right: 0px;

    &:hover,
    &:focus {
      color: @mn-teal;
      text-decoration: none;
      cursor: pointer;
      .opacity(1);
    }

    // Additional properties for button version
    // iOS requires the button element instead of an anchor tag.
    // If you want the anchor version, it requires `href="#"`.
    button& {
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
    }
  }
}
// end overrides

// Modal backdrop CSS for ngStrap
.modal-backdrop.am-fade {
  opacity: .5;
  transition: opacity .15s linear;
  &.ng-enter {
    opacity: 0;
    &.ng-enter-active {
      opacity: .5;
    }
  }
  &.ng-leave {
    opacity: .5;
    &.ng-leave-active {
      opacity: 0;
    }
  }
}

.modal-header {
  background: #f8f8f8;
}

.modal-footer .btn {
  margin-right: 10px;
  @media @sm-max {
    // prevents buttons from being overlapped
    // by intercom icon on small screens.
    float:left;
  }
}

/*
This is a fix to a known issue with BS modals in Mobile Safari.
It was proposed as part of a recent PR on Bootstrap, but hasn't
been (and may not be) merged. See: https://github.com/twbs/bootstrap/commit/67d5eeb90273153c80d1f5ee685f21704932f57b
*/
.modal {
  -webkit-overflow-scrolling: auto!important;
}

// angular-strap removes the .modal-open class from the body whenever a modal
// is closed, so when multiple modals are open, closing one would cause the
// body's scrollbar to reappear. To work around this, we add our own classes
// (that copy .modal-open's rules) to the body while a modal is open.
.mun-modal-open, .mun-modal-react-open {
  .modal-open();

  .modal { .modal-open .modal() }
}
