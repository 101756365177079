.client-overview {
  hr.one-hr-only + hr.one-hr-only {
    display: none;
  }
}

.labor-alert {
  a, i {
    color: @mn-white;
  }
  .labor-message {
    display: inline-block;
    & > a {
    // Using the default text-decoration property doesn't look very good here. The underline
    // is almost indistinguishable from the text. Here I'm just mimicing the default, but adding space.
      border-bottom: 1px solid;
      margin-left: 5px;
      padding-bottom: 1px;
      &:hover {
        color: darken(@mn-white, 10%);
        text-decoration: none;
      }
    }
  }
  &.panel > .panel-body {
    padding: 15px 20px;
  }
}
