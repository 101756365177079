@import (reference) '~bootstrap/less/mixins/text-overflow.less';
@import (reference) '~bootstrap/less/variables.less';

mun-imocode-search {
  display: inline-block;
  vertical-align: top;

  & > .dropdown {
    & > .dropdown-toggle {
      text-align: left;
    }

    & > .dropdown-menu {
      min-width: 100%;

      & > .dropdown-header {
        padding-right: 2em;
      }
    }
  }

  .imo-notice {
    margin-top: @line-height-computed/2;
  }
}

mun-imocode-refiner {
  & .mun-imocode-row {
    cursor: pointer;
  }
}

.btn.mun-imocode {
  .text-overflow();
}
