#labs-flowsheet {
  overflow-x: auto;

  .flowsheet-header-container {
    padding:1px 12px;
  }
}


.flowsheet-header-container {
  background: @mn-header-bg;
  color:@mn-header-color;
  .close {
    color:@mn-header-color;

  }
  .close:hover {
    color:#ddd;
  }
}




table.flowsheet.mn-table-dontuse {
  width:100%;

  .event {
    text-align: left;
    line-height: 18px;

    .heading {
      font-weight: bold
    }

    .subheading {
      font-weight: bold;
    }
    p {
      line-height: 18px;
    }

   img {
      float: left;
      margin: 0;
      margin-right: 6px;
      width:16px;
      height:16px;
    }
  }

  tr.flowsheet-labels th {
    border:none
  }

  th {
    background: @mn-sub-header-bg;
    color: @mn-sub-header-color;
    font-weight: bold;
  }
  // Explicit override for labor flow bullshit
  &.labor-flowsheet {
    th {
      background: @mn-white;
    }
    tr.flowsheet-labels > th {
      background: @mn-sub-header-bg;
    }
  }

  td, th {
    border:1px solid @mn-border-gray-lt;
    text-align: center;
    padding: 5px;
  }

  tbody {

    .flowsheet-record {

      .flowsheet-section-header {
        font-weight: bold;
      }

      th.flowsheet-row-header {
        background: transparent;
        color:@text-color;
      }

      .labs-units, .labs-norm {
        background: @mn-gray-very-light;
      }
    }

    tr.reviewer-flowsheet {

      th.flowsheet-row-header, .labs-value {
        background: @mn-medium-purple;
      }
    }
  }
}

.flowsheet-row,
.flowsheet thead tr th,
.flowsheet tfoot tr th,
.flowsheet thead,
.flowsheet-record,
.flowsheet-record-alt,
table.labor-flowsheet td.flowsheet-timestamp,
table.labor-flowsheet td.flowsheet-initials,
.lipp_flowsheets_wrapper table.flowsheet {
  border-color: @mn-border-gray-lt
}

.lipp_flowsheets_wrapper table.flowsheet {
  border-bottom: 1px solid @mn-border-gray-lt;
}



#prenatal-flowsheet {

  td {
    background: transparent;
  }

  .flowsheet-record.alt, .flowsheet-other-detail.alt {
    background: @mn-gray-very-light;
  }

}

// For the export
.lipp-flowsheet {
  .flowsheet-header-container {
    display: none;
  }
  .flowsheet.date-row {
    font-weight: bold;
  }
  .flowsheet tr.flowsheet-other-detail {
    text-align: left;
  }
  .labor-flowsheet td.flowsheet.event {
    text-align: left;
  }
  .labor-flowsheet .heading {
    font-weight: bold;
  }
  .labor-flowsheet .subheading {
    font-weight: bold;
  }
}
