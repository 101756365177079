// Basic styling for the details/summary elements.

// Hide Chrome's arrow for consistency
details summary::-webkit-details-marker { display: none; }

// Revert styles added by details polyfill
summary::before {
  content: none;
  padding-right: 0;
  font-size: inherit;
}
[open] > summary::before {
  content: none;
}

// Helper classes for hiding elements when details is open or closed
details[open] .hidden-open { display: none; }
details:not([open]) .hidden-closed { display: none; }

details:focus, summary:focus {
  outline: none;
}

details.panel {
  summary.panel-heading {
    cursor: pointer;
  }
}
