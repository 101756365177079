#eve {
  #procedures {
    .patient-table-styling();
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0px;
    }
    .table > tbody + tbody {
       border-width:1px;
    }
    td, th {
      &.units {
        text-align: center;
      }
      &.modifier {
        text-align: center;
      }
      &.fee {
        text-align: right;
      }
      &.edit {
        text-align: center;
      }
    }
    tr {
      &.global-procedure {
        span, i, input {
          opacity: 0.6;
        }
        background: #DDD;
      }
    }
    td {
      &.procedure {
        span {
          margin-right: 5px;
        }
        .tooltip-inner {
          max-width: 400px;
          opacity: 1;
        }
        span.code {
          font-weight: bold;
        }
      }
    }
  }
}

#eve-procedures-modal {
  @marginBottom: 15px;
  .modal-header {
    h1, h2, h3, h4, h5, h6 {
      display: inline-block;
      margin-right: 10px;
    }
    div {
      display: inline-block;
      a {
        text-decoration: none;
      }
    }
  }
  p {
    margin-bottom: @marginBottom;
  }
  .encounter-procedure-form {
    @padding: 20px;
    background-color: #F2F2F2;
    padding: @padding;
    margin-bottom: @marginBottom;
    .remove-procedure {
      position: relative;
      display: block;
      margin: -@padding -@padding @padding @padding;
      button.close {
        float: none;
        position: absolute;
        top: 3px;
        right: 5px;
        // color: #000000;
        //opacity: 1;
        //font-weight: normal;
      }
    }
  }
  .global-procedure-code-list {
    background-color: #CCCCCC;
    padding: 15px;
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0px;
    }
    .checkbox {
      span.procedure {
        margin-right: 5px;
        span.code {
          font-weight: bold;
        }
      }
    }
    .checkbox:last-of-type {
      margin-bottom: 0px;
    }
  }
  .procedure-form-block {
    .diagnosis {
      position: relative;
      a.add-modifer {
        text-decoration: none;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
    .date-inputs {
      font-size: 0px;
      @iconWidth: 10%;
      input {
        width: (100%-@iconWidth)/2;
        display: inline-block;
      }
      i {
        width: @iconWidth;
        text-align: center;
        vertical-align: middle;
        font-size: 13px;
      }
    }
    .datepicker-container {
      .datepicker {
        width: 250px;
      }
    }
    mun-cptcode-search {
      display: block;
    }
  }
  .modal-controls {
    &.clearfix {
      margin-bottom: 10px;
    }
    .add-procedure {
      margin: @padding-base-vertical 0px; //look at this
      // font-size: @font-size-base + 1px;
      font-weight: 400;
      text-decoration: none;
    }
    .save-controls {
      button {
        margin-left: 10px;
      }
    }
  }
}
