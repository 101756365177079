.duna--filters-subsection {
  margin-bottom: 25px;

  .input-group {
    margin-top: 7px;
  }
}

.duna--index-filters { // currently in sidebar menu
  .panel-heading .panel-title {
    @media @sm-min {
      font-size: 14px;
    }
    @media @md-min {
      font-size: 18px;
    }
  }
}

.duna--index-item {
  .img-container {
    max-width: 30%;
    margin-right: 20px;
    @media @xs-min {
      max-width: 20%;
    }
  }

  .media-heading {
    font-size: 16px;
    @media @xs-min {
      font-size: 18px;
    }
    @media @sm-min {
      font-size: 20px;
    }
    @media @md-min {
      font-size: 24px;
    }
  }
}
