.inline-search {
  min-width: 25em;

  .mn-name {
    font-weight: 600;
    color:@mn-purple;
  }

  .form-control {
    width: 100%;
    font-size: 14px;
    padding-right: @padding-base-horizontal + 18px + @padding-base-horizontal;
  }

  .input-glyph {
    font-size: 18px;
    line-height: 20px;
  }

  kerbin-avatar img, .kerbin-avatar {
    max-height: @line-height-computed * 30/18;
    width: auto;
    margin-right: 1em;
  }

  .mn-name {
    display: inline-block;
    vertical-align: middle;
    min-width: 12em;
  }

  .mn-discriminator {
    display: inline-block;
    vertical-align: middle;
    min-width: 6em;
    margin-right: 1em;
  }

  li.focused a, li.focused .mn-name, li.focused .text-muted {
    background: @mn-purple !important;
    color: #fff !important;
  }
}

.inline-search .search-trigger {
  display: none;
  color: #fff;
  margin-top: 5px;
}
