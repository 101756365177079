.duna--resource-schedule {
  .resource-schedule-week {
    background: #f6f6f6;
    padding: 15px;
  }

  .panel-crowd .panel + .panel {
    margin-top: 15px;
  }

  .resource-schedule-item .panel-body {
    border: 1px solid @mn-gray-light;
    padding: 15px;
    background: @mn-white;
  }

  .week-label {
    background-color: #f6f6f6;
    font-weight: 600;
    font-size: 16px;
    padding: 25px;
    text-align: center;
    vertical-align: middle;
    width: 75px;
  }

  .media {
    & > a {
      max-width: 45px;
    }
  }

  .media-body {
    & p {
      margin-bottom: 0;
    }
  }
}
