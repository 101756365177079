.timepicker {
  min-width: 0px;
  width: auto;

  .btn-default {
    margin-right: 0;
  }
  table {
    height: 100%;
  }
  th {
    background-color: transparent;
  }
  tbody .btn {
    width: 100%;
  }

  .fa {
    // Override rule that makes icons fixed-width in dropdowns
    width: inherit;
  }
}
