// Empty States. Aka: No Content Screens.
// imported by kearth (ehr.less)

.is-empty {
  padding: 25px;

  h2 {
    margin-top: 0;
    font-size: 28px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 28px;
  }
}
