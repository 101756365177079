.highlights-toggle-open {
  z-index: 1000;
  position:absolute;
  top: 0;
  right: 0;
  background: @mn-sidebar-bg!important;
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  color: @mn-gray-dark;
  width: 42px;
  padding: 10px 12px!important;
  &:hover {
    background-color: darken(@mn-sidebar-bg, 15%);
  }

}

.highlights-toggle-close {
  color: @mn-gray-dark;
  &:hover {
    color: darken(@mn-gray-dark, 15%);
  }
}

.highlights-panel {
  width: 100%;
  padding: 10px 20px 5px 20px;
  background-color: @mn-white;
  color: @mn-gray-dark;
}

.highlights-title {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.highlights-list {
  list-style-type: none;
  padding-left: 0;
  padding-bottom: 0;
}

.highlights-main-item {
  padding: 15px 0 12px 0px;
  border-top: 1px solid fadeout(@mn-gray-med,90%);
  position:relative;

  summary { // title
    font-size: 1.1em;
    font-weight: bold;
    padding: 0 0 5px 0;
  }
  details {
    &> .probmedal {
      margin-top: 10px;
      ul {
        list-style-type: none;
        padding-left: 0px;
        overflow: hidden;
      }
      li {
        padding-left: 0px;
      }
      label {
        margin-bottom: 0px;
      }
      p {
        margin: 0 0 6px;
      }
      button {
        color: @mn-gray-dark;
      }
    }
  }

  .add-btn {
    .pull-right;
    color: @mn-gray-dark;
    padding-top: 5px;
  }
  .text-muted {
    color: lighten(#777777, 10%);
  }
}

.vnavbar-nav-item {
  &.active .highlights-toggle-open {
    display: none;
  }
}
