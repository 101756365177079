.signup-middle h1,
.signup-middle .h1 {
  font-size: 25px;
  font-weight: normal;
  color: #666;
}
.signup-middle h2,
.signup-middle .h2 {
  font-size: 25px;
  font-weight: normal;
  color: #5f5478;
}
.signup-middle h2 button,
.signup-middle .h2 button {
  margin-left: 5px;
}
.signup-middle h1,
.signup-middle .h1,
.signup-middle h2,
.signup-middle .h2,
.signup-middle h3,
.signup-middle .h3 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.signup-middle .get-started {
  display: flex;
  align-items: center;
}
.signup-middle .get-started p {
  font-size: 16px;
}
.signup-middle .get-started .signup-people-img {
  max-width: 100%;
  height: auto;
  max-height: 350px;
  border-radius: 0px 0px 50px 50px;
  display: flex;
  margin-left: auto;
}
.signup-middle .thank-you {
  display: flex;
  align-items: center;
}
.signup-middle .thank-you p {
  font-size: 16px;
}
.signup-middle .thank-you .thank-you-person-img {
  max-width: 100%;
  height: auto;
  max-height: 450px;
  border-radius: 0px 0px 50px 50px;
  display: flex;
  margin-left: auto;
}
.signup-middle .contact-info,
.signup-middle .error-info {
  background-color: #f2dede;
  padding: 15px;
  font-size: 16px;
}
.signup-middle .radio,
.signup-middle .checkbox {
  margin-top: 0;
}
.signup-middle hr {
  border-top: 1px solid #979797;
}
