#prob-med-al {
  .table-responsive {
    th {
      // Allows th text to overflow boundaries
      // which prevents stacking of arrow icons
      // and text
      @media @sm-max {
        white-space: nowrap;
        overflow:hidden;
      }
    }
    .single-icon-col {
      width:25px; // default for single-icon td and th
    }

    // For table cells on the bottom row, the drop down menu
    // is hidden by .table-responsive's overflow: scroll. overflow-y:visible
    // does not work when overflow-x is anything but visible.
    // So, here I'm repositioning the dropdown and making it
    // more compact.
    .dropdown-menu {
      top: -43px;
      left: 30px;
      a {
        padding-top: 1px;
        padding-bottom: 1px;
      }
    }
  }
}

.prob-med-al-overview {
  ul {
    list-style-type: none;
    padding-left: 0px;
  }
}

.imo-notice {
  font-size: 10px;
}
