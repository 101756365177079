@import (reference) '~@maternity/kerbin/app/css/style.less';

smart-question {
  display: block;
}

.fivept-scale {
  .fivept-scale-cell {
    background-color: transparent;
    width: 20%;
  }

  .fivept-scale-nopadding {
    padding: 0;
  }

  .fivept-scale-label {
    cursor: pointer;
    font-weight: normal;
    margin: 0;
    padding: 5px;
    width: 100%;
  }
}

.moho-past-pregs {
  display: flex;
  flex-wrap: wrap;
}
.moho-past-pregs-item {
  // Add gap between items
  padding-right: @padding-base-horizontal;
  // Grow to fill available space
  flex-grow: 1;
}
