mun-cptcode-search {
  display: inline-block;
  vertical-align: top;
}
mun-cptcode-search > .dropdown > .dropdown-toggle {
  text-align: left;
}
mun-cptcode-search > .dropdown > .dropdown-menu {
  min-width: 100%;
}
mun-cptcode-search > .dropdown > .dropdown-menu > .dropdown-header {
  padding-right: 2em;
}
mun-cptcode-refiner .mun-cptcode-row {
  cursor: pointer;
}
.btn.mun-cptcode {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
