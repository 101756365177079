.coverage-accordion {
  &.panel {
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  label {
    margin: 0 0 -6px;
    display: block;
  }
}
