.client-list-header-menu {
  @media @xs-max {
    float: none!important;
    margin-top: 10px;
    input, select {
      margin-bottom:5px;
    }
  }
  .btn {
    @media @xxs-max {
      margin-bottom: 0px;
    }
  }
}

.client-list-view {
  .panel .panel-body {
    padding-top: 5px;
    @media @sm-max {
      padding: 5px;
    }
  }
  .responsive-data-table {
     i {
      color: @mn-gray-med;
      cursor: pointer;
      padding: 3px;
    }
  }
  .toggle-all-fields {
    top: 35%;
  }
  td a.media:hover {
    // prevents highlighting of text contained in a tag
    text-decoration: none;
  }
}
