jool-appointment-edit {

  button.timeslot {
    white-space: normal;
  }

  .selected-timeslot {
    display: flex;
  }

}
