// bootstrap overrides

.panel {
  margin-bottom: @panel-body-padding;
  box-shadow: none;
  -webkit-box-shadow:none;
  background-clip: padding-box;
  &:not(.panel-border) {
    border-color: transparent;
  }
}

.panel-title {
  font-size: 18px;
}

// end overrides

// Tighten up margin between panels without hiding overflow
.panel-crowd {
  .panel-group();
  .panel {
    overflow: visible;
  }
}
