.schema-form--display {
  .form-group {
    // Remove bottom margin in display mode so the form is a bit more compact
    // (for consistency with the angular qtypes which didn't use .form-group)
    margin-bottom: 0;
  }
}

// TODO: moho still contains some styles used by qs.FivePoint and
// mnqs.PastPregsSummary.
