.kerbin--javascript-disabled {
  margin-top: 100px;
}
.kerbin--unsupported-browser,
.kerbin--cookies-disabled {
  display: none;
}
.no-supportedbrowser .kerbin--unsupported-browser,
.no-cookies .kerbin--cookies-disabled {
  display: block;
  margin-top:60px;
}

.no-supportedbrowser div[ng-app],
.no-cookies div[ng-app] {
  display: none;
}

.kerbin--cookies-disabled,
.kerbin--unsupported-browser,
.kerbin--javascript-disabled {
  .panel-title{
    line-height:inherit;
  }
}
