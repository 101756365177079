// bootstrap overrides

footer {
  padding: 10px 16px 8px;
  background: white;
  text-align: center;
  font-size: .85em;
  border-top: 1px solid #eee;

  @media @sm-max {
    font-size: .8em;
    padding: 5px 25px 5px;
  }
  @media @xs-max {
    font-size: .6em;
  }

  .smerfline {
    @media @sm-max {
      line-height: 13px;
    }
  }

  hr {
    margin: 20px 0 15px;
  }

  img {
    margin-bottom: 10px;
  }

  .custom-logo {
    img {
      max-height: 100px;
    }
  }
}
