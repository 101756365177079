#client-nav {
  min-height:0px;
  background: @mn-sidebar-bg;
  padding:5px inherit;
  border-color:darken(@mn-sidebar-bg,10%);
  overflow-x: visible;
  padding-top: 0;

  header {
    padding: 0 @padding-large-horizontal @padding-large-vertical;
  }

  @media @md-min {
    height: 100vh;
    .flex;
    .flex-column;

    header {
      padding-top: @navbar-height + @navbar-margin-bottom + @line-height-computed;

      // Show in front of the nav
      z-index: 1;

      // Give the header a defined bottom edge
      border-bottom: 1px solid darken(@mn-sidebar-bg, 10%);

      .flex-inflexible
    }

    .client-nav-body {
      overflow-y: scroll;
      .flex-grow;
      .flex-shrink;
    }
  }

  & > header {
    color: @mn-gray-dark;
    .text-left;
    @media @md-min{
      .text-center;
    }

    kerbin-avatar {
      float:left;
      margin-right:10px;
      @media @md-min {
        float:none;
      }

      img {
        max-height: 3*@line-height-computed;
        width:auto;
      }
    }
    .kerbin-avatar {
      float:left;
      margin-right:10px;
      @media @md-min {
        float:none;
      }

      max-height: 3*@line-height-computed;
      width:auto;
    }

    h4 {
      @media @xs-max {
        font-size: 17px;
      }
    }

    .unique-preferred {
      display: inline-block;
      @media @md-min {
        display: block;
      }
      .full-name {
        display: inline-block;
        margin-bottom: 5px;
      }
    }

    // Hooray specificity rules...
    small {
      color: inherit;
    }

    .text-muted {
      color: @mn-gray-med;
    }

    .label {
      display: inline-block;
      // Spill onto a new line instead of adding a scroll bar
      white-space: normal;
    }
    .ga-age-text.label {
      // The default 75% is too small and 100% is too wide
      font-size: 90%;
      color: @mn-white;
    }
    .edd-quicklink {
      color: inherit;
      &:hover {
        color: @mn-teal;
      }
    }
  }
  .dropdown-toggle {
    display:block;
    float:left;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right:10px;
    color: @mn-gray-dark;

    @media @md-min, print {
      display:none;
      float:none;
    }
  }

  .dropdown-menu {
    top:100%;
    z-index: 999;
    margin:-1px 0 0 0;
    border-radius:0;
    background: @mn-sidebar-bg;
    @media @md-min {
      width:100%;
      z-index: 0;
      top:auto;
      display:block;
      box-shadow:none;
      border: none;
      padding-bottom: @mn-footer-height;
      position: static;
    }
  }

  // Need to account for the extra .client-nav-body element when opening the
  // dropdown on mobile
  &.open .client-nav-body > .dropdown-menu {
    display: block;
  }

  .vnavbar.open .dropdown-menu {
      position: absolute;
      float: none;
      width: auto;
      margin-top: 0;
      border: 0;
      box-shadow: none;
      visibility: visible!important;

  }

  .vnavbar-nav-item {
    &.active {
      border-left: 4px solid lighten(@mn-header-bg, 10%);
      & > a, .vnavbar-nested-summary {
        background: @mn-bg-light;
        color:@mn-gray-dark;
        border-color: darken(@mn-sidebar-bg, 10%);
        &:hover {
          background:@mn-bg-light;
        }
      }
    }

    &:hover {
      & > a,
      .vnavbar-nested-summary {
        color: @mn-gray-dark;
        background: darken(@mn-sidebar-bg, 2%);
      }
    }

    & > a,
    .vnavbar-nested-summary {
      border-color: darken(@mn-sidebar-bg,5%);
      color: @mn-gray-med;
      font-size: 14px;
    }
  }

  .nested-nav-summary {
    background: @mn-gray-dark!important;
    border-color: @mn-gray-light;
    &::before {
      color: @mn-gray-light;
    }
  }

  .nested-nav-list {
    & > li > a {
        font-size: 14px;
        border-top: 1px solid;
        border-color: #5a5a5a!important;
        padding-left: 35px;
        text-decoration: none;
      }
    }

}

// The null animation is used to inform ng-animate of timings when the actual animation is happening
// else where.

.keyframes(null, {});

.keyframes(fade-in, {
  from { opacity: 0; }
  to   { opacity: 1; }
});

.fade-in.ng-enter { .animation(0.5s fade-in) }


.keyframes(client-nav-enter, {
  from { .translate3d(-(@vnavbar-width + @vnavbar-margin), 0, 0); }
  to   { .translate3d(0, 0, 0); }
});

.keyframes(client-nav-push-enter, {
  // margin-left: @vnavbar-width + @vnavbar-margin is applied in layout
  from { margin-left: 0; .translate3d(0, 0, 0); }
  to   { margin-left: 0; .translate3d(@vnavbar-width + @vnavbar-margin, 0, 0); }
});

@client-nav-easing: cubic-bezier(.95,0,.62,1);

#sidebar {
  @media @md-min {
    &.ng-enter               { .animation(0.5s null) }
    &.ng-leave               { .animation(0.5s null) }

    &.ng-enter   #client-nav { .animation(0.5s @client-nav-easing client-nav-enter) }
    &.ng-leave   #client-nav { .animation(0.5s @client-nav-easing client-nav-enter reverse) }

    &.ng-enter ~ #middle     { .animation(0.5s @client-nav-easing client-nav-push-enter) }
    &.ng-leave ~ #middle     { .animation(0.5s @client-nav-easing client-nav-push-enter reverse) }
  }
}


.show-client-nav, .hide-overflow { // hides #highlights overflow when #sidebar is not fixed
  position:relative;
  overflow:hidden;

  @media @md-min {
    overflow: visible;
  }
}
