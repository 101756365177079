.braintree-hosted-fields {
  &:extend(.form-control);
  &:extend(input);
  outline: none;
  padding: 0;
}

.braintree-hosted-fields-focused {
  &:extend(.form-control:focus);
  &:extend(input:focus);
}

.input-glyph-group-right {
  .braintree-credit-card-logo.input-glyph {
    line-height: @input-height-base;
    padding: 0;
  }
}

.braintree-credit-card-logo {
  font-size: @input-height-base;
}

.braintree-error {
  &:extend(.bg-danger);
  font-weight: bold;
  padding: 1rem;
}
