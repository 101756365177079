mun-imocode-search {
  display: inline-block;
  vertical-align: top;
}
mun-imocode-search > .dropdown > .dropdown-toggle {
  text-align: left;
}
mun-imocode-search > .dropdown > .dropdown-menu {
  min-width: 100%;
}
mun-imocode-search > .dropdown > .dropdown-menu > .dropdown-header {
  padding-right: 2em;
}
mun-imocode-search .imo-notice {
  margin-top: 10px;
}
mun-imocode-refiner .mun-imocode-row {
  cursor: pointer;
}
.btn.mun-imocode {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
