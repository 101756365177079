// bootstrap overrides

small, .small {
  font-size: 92.5%; // 13px base * 92.5% ~= 12px;
}
.xsmall {
  font-size: 85%; // 13px base * 85% ~= 11px;
}

// Override type
.text-info {
  color: @mn-purple;
}

// end overrides

.text-pre-wrap {
  white-space: pre-wrap;
}

// makes the font 50% larger relative to the icon container
// 13px base * 150% ~= 20px
.@{fa-css-prefix}-xl {
  font-size: (3em / 2);
  line-height: (2em / 3);
  vertical-align: -25%;
}

.text-overflow {
  .text-overflow();
}

.unstyled-link-text {
  color: @text-color;
  cursor: default;
}
