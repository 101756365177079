.files-list {
  .toggle-all-fields {
    top:40%;
  }
}

.file-info {
  max-width: 90%;
  @media @md-min {
    max-width: 400px;
  }
  @media @lg-min {
    max-width: 600px;
  }
  .fa-file {
    color: @mn-teal;
  }

  .file-privacy-icon-wrapper {
    position: relative;
    height: 50px;
    margin-right: 15px;
    padding: 0 5px;
    .private {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: @mn-red;
      color: @mn-white;
      font-size: .75em;
      text-align: center;
    }
  }

  .file-name {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    color: @mn-teal;
  }

  .file-desc {
    white-space: normal;
    font-size: 1em;
    font-weight: bold;
    margin: 0;
  }
}
