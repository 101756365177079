@default-color: #333;
@default-bg: #ddd;
@default-border: #ccc;

#payers {
  button.in-process {
    .button-variant(@default-color; @default-bg; @default-border)
  }
  .btn.disabled, .btn[disabled] {
    opacity: 1,
  }
  tr {
    td {
      vertical-align: middle;
    }
  }
}
