@default-color: #333;
@default-bg: #ddd;
@default-border: #ccc;

#eve {
  #claims {
    button.in-process {
      background: #fff;
      border:none;
    }
    .status {
      background: @mn-gray-light;
    }
    tbody {
      tr {
        &.pointer{
          cursor: pointer;
        }
      }
    }
    td, th {
      &.amount {
        text-align: right;
      }
      &.payment-status, &.claim-status {
        @width: 150px;
        @height: 26px;
        .btn-group {
          position: static;
          button {
            padding: 3px 13px;
            height: @height;
            width: @width;
          }
          ul.dropdown-menu {
            width: @width;
            outline: none;
            margin-top: @height;
            left: auto;
            right: auto;
            top: auto;
            bottom: auto;
          }
          .btn.disabled, .btn[disabled] {
            opacity: 1,
          }
          &.open {
            .dropdown-toggle {
              box-shadow: none;
              -webkit-box-shadow:none;
            }
          }
        }
      }
      &.edit {
        text-align: center;
        ul {
          text-align: left;
          min-width: 100px;
          right: 0;
          left: auto;
        }
      }
      &.rejection-reason {
        span {
          margin-right: 5px;
        }
      }
    }
  }
}

#eve-claims-modal {
  .modal-dialog {
    width:90%;
  }
  .controls {
    .control-item {
      margin-left: 2.5px;
      margin-right: 2.5px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .row {
    .alert {
      display: inline-block;
    }
  }
  .provider {
    .btn-group {
      min-width: 80px;
    }
  }
  .claim-info {
    margin-top: @modal-inner-padding;
    .info-block {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .claim-heading {
      font-weight: 700;
      margin-right: 3px;
    }
  }
  table {
    margin-top: @modal-inner-padding;
    .patient-table-styling();
    td, th {
      &.fee {
        text-align: right;
      }
      &.delete {
        text-align: center;
      }
    }
  }
}

@media print {
  #eve-claims-modal {
    position: relative;
    font-size: 12px;
    [class*="col-sm-"] {
      float: left;
    }
    .modal-content {
      border: none;
    }
    .modal-dialog {
      width:100%;
    }
    table, th, td {
      border: 1px solid black !important;
      border-collapse: collapse;
    }
    .table-responsive {
      overflow: visible;
    }
  }
}
