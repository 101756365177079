// Use these classes to override padding/margin of elements or if you just need a little spacer between elements.
// imported by kearth (ehr.less)

// No Margin
.no-margin {margin: 0 !important ;}
.no-margin-top {margin-top: 0 !important ;}
.no-margin-bottom {margin-bottom: 0 !important ;}
.no-margin-right {margin-right: 0 !important ;}
.no-margin-left {margin-left: 0 !important ;}

// No Padding
.no-padding {padding: 0 !important ;}
.no-padding-top {padding-top: 0 !important ;}
.no-padding-bottom {padding-bottom: 0 !important ;}
.no-padding-right {padding-right: 0 !important ;}
.no-padding-left {padding-left: 0 !important ;}

// Add Margin
.margin-5 {margin: 5px !important ;}
.margin-10 {margin: 10px !important ;}
.margin-15 {margin: 15px !important ;}
.margin-20 {margin: 20px !important ;}
.margin-25 {margin: 25px !important ;}

// Add Margin Top
.margin-top-5 {margin-top: 5px !important ;}
.margin-top-10 {margin-top: 10px !important ;}
.margin-top-15 {margin-top: 15px !important ;}
.margin-top-20 {margin-top: 20px !important ;}
.margin-top-25 {margin-top: 25px !important ;}

// Add Margin Bottom
.margin-bottom-5 {margin-bottom: 5px !important ;}
.margin-bottom-10 {margin-bottom: 10px !important ;}
.margin-bottom-15 {margin-bottom: 15px !important ;}
.margin-bottom-20 {margin-bottom: 20px !important ;}
.margin-bottom-25 {margin-bottom: 25px !important ;}

// Add Margin Right
.margin-right-5 {margin-right: 5px !important ;}
.margin-right-10 {margin-right: 10px !important ;}
.margin-right-15 {margin-right: 15px !important ;}
.margin-right-20 {margin-right: 20px !important ;}
.margin-right-25 {margin-right: 25px !important ;}

// Add Margin Left
.margin-left-5 {margin-left: 5px !important ;}
.margin-left-10 {margin-left: 10px !important ;}
.margin-left-15 {margin-left: 15px !important ;}
.margin-left-20 {margin-left: 20px !important ;}
.margin-left-25 {margin-left: 25px !important ;}

// Add Padding
.padding-5 {padding: 5px !important ;}
.padding-10 {padding: 10px !important ;}
.padding-15 {padding: 15px !important ;}
.padding-20 {padding: 20px !important ;}
.padding-25 {padding: 25px !important ;}

// Add Padding Top
.padding-top-5 {padding-top: 5px !important ;}
.padding-top-10 {padding-top: 10px !important ;}
.padding-top-15 {padding-top: 15px !important ;}
.padding-top-20 {padding-top: 20px !important ;}
.padding-top-25 {padding-top: 25px !important ;}

// Add Padding Bottom
.padding-bottom-5 {padding-bottom: 5px !important ;}
.padding-bottom-10 {padding-bottom: 10px !important ;}
.padding-bottom-15 {padding-bottom: 15px !important ;}
.padding-bottom-20 {padding-bottom: 20px !important ;}
.padding-bottom-25 {padding-bottom: 25px !important ;}

// Add Padding Right
.padding-right-5 {padding-right: 5px !important ;}
.padding-right-10 {padding-right: 10px !important ;}
.padding-right-15 {padding-right: 15px !important ;}
.padding-right-20 {padding-right: 20px !important ;}
.padding-right-25 {padding-right: 25px !important ;}

// Add Padding Left
.padding-left-5 {padding-left: 5px !important ;}
.padding-left-10 {padding-left: 10px !important ;}
.padding-left-15 {padding-left: 15px !important ;}
.padding-left-20 {padding-left: 20px !important ;}
.padding-left-25 {padding-left: 25px !important ;}


