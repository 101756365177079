.vnavbar {
  width: 100%;
  margin: 0;

  @media @md-min {
    width: @vnavbar-width;
    margin: 0 @vnavbar-margin;
  }
}

.vnavbar-nav, .vnavbar-nested-list {
  list-style: none;
  padding: 0;
  text-decoration: none;
  font-size: 14px;
  .vnavbar-nav-item {
    position:relative;
    & > a {
      padding: 10px 20px;
      display: block;
      border-top: solid 1px;
      border-bottom: solid 1px;
      // adjacent borders overlap
      margin-bottom: -1px;
    }

    & > a,
    & > details > summary {
      .transition(color 0.25s ease);
    }

    &.active {
      border-left: 2px solid;
    }

    &:hover {
      & > a,
      & > details > summary {
        text-decoration: none;
      }
    }
  }
}

.vnavbar-nav {
  width: @vnavbar-width;
}

// TODO: Match color of sublist, rather than indenting
.vnavbar-nested-list {
  width: 100%;
  .vnavbar-nav-item > a {
    padding-left: 35px;
  }
}


.vnavbar-nested-details[open] > summary::before {
  .rotate(90deg);
}

.vnavbar-nested-summary {
  position:absolute;
  top: 0; right: 0;
  height: 42px; width: 42px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  margin-bottom: 1px;
  padding: 10px 17px;
  text-align: center;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &::before {
    .fa();
    content: @fa-var-caret-right;
    font-size: 1.25em;
    // For some reason chrome doesn't apply this transition when
    // transform property is changed by details[open] selector, but it
    // doesn animate if I toggle the transform property in the inspector.
    .transition-transform(0.3s linear);
  }
  // Duplicate `content` rule to override details polyfill styles
  [open] > &::before {
    content: @fa-var-caret-right;
  }
}

.vnavbar-fixed-left {
  position:relative;
  top: 0;
  left: 0;
  border-right: solid 1px;
  margin-left: 0;

  @media @md-min {
    position:fixed;
    top: 0;
    left: 0;
  }

}

.vnavbar-fixed-right {
  position: fixed;
  top: 0; right: 0;
  bottom: 0;
  border-left: solid 1px;
  margin-right: 0;
}

.vnavbar-static-left {
  z-index: @zindex-navbar;
  border-right: solid 1px;
  margin-left: 0;
}

.vnavbar-static-right {
  z-index: @zindex-navbar;
  border-left: solid 1px;
  margin-right: 0;
}


