[mun-spinner].mun-loading.mun-spinner-replace > * {
  visibility: hidden;
}
[mun-spinner].mun-loading::before {
  display: block;
  content: " ";
  margin: auto;
  padding: 2em;
  height: 90px;
  background: url('~@maternity/kerbin/app/images/icons/loading.gif') no-repeat 50%;
  text-align: center;
}
mun-spinner > .mun-loading {
  content: " ";
  margin: auto;
  padding: 2em;
  height: 90px;
  background: url('~@maternity/kerbin/app/images/icons/loading.gif') no-repeat 50%;
  text-align: center;
}
