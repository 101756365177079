.settings-nav {
  a {
    color: @mn-gray-dark;
  }

  .nav {
    padding: 0;
    > li {
      padding-bottom: 95%;
      width: 95%;
      margin: 2.5%;
      background-color: white;
      > a {
        cursor: pointer;
        position: absolute;
        text-align: center;
        width: 100%;
        height: 100%;
      }
    }
  }

  .setting-wrapper {
    display: table;
    height: 100%;
    width: 100%;
    .setting {
      display: table-cell;
      vertical-align: middle;
      @media (max-width: @screen-xs-max) {
        font-size: 3vw;
        .fa {
          font-size: 9vw;
        }
      }
      @media (min-width: @screen-sm-min) {
        font-size: 2vw;
        .fa {
          font-size: 6vw;
        }
      }
      @media (min-width: @screen-md-min) {
        font-size: 1vw;
        .fa {
          font-size: 3vw;
        }
      }
    }
  }
}
