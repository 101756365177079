#main.login {
  background-color: @mn-header-bg;
  width: 100%;
  height: 100%;
  min-height: 375px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login-form {
  width: 400px;
  height: 375px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  @media @xs-max {
    width: 300px;
    height: 375px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .panel-login {
    padding: 0;
    margin: 0;

    @media @xs-max {
      padding: 0;
      margin: 0;
    }

    .panel-heading {
      border: none;
      padding: 0;
      margin: 0;

      @media @xs-max {
        border: none;
        padding: 0;
        margin: 0;
      }
    }

    h1 {
      color: #fff;
      padding: 0;
      margin: 0;
    }

    a {
      color: #fff;
    }

    .form-control {
      font-size: 17px;
      color: @text-color;

      @media @xs-max {
        font-size: 13px;
        color: @text-color;
      }
    }

    .btn.btn-primary {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    ::-webkit-input-placeholder {
      color: darken(#fff, 15%);
    }

    :-moz-placeholder { /* Firefox 18- */
      color: darken(#fff, 15%);
    }

    ::-moz-placeholder {  /* Firefox 19+ */
      color: darken(#fff, 15%);
    }

    :-ms-input-placeholder {
      color: darken(#fff, 15%);
    }
  }
}

