.kerbin--loading-message {
  margin: auto;
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
  height: 90px;
  text-align: center;
  z-index: 2000;
  background: url('../../images/icons/loading.gif') no-repeat 50%;

  display: none;
}

.ng-cloak.kerbin--loading-message {
  display: block;
}

.kerbin--loading-data-message {
  margin: auto;
  padding: 2em;
  height: 90px;
  background: url('../../images/icons/loading.gif') no-repeat 50%;
  text-align: center;
}
