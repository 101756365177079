.export-preview {
  .condensed-spacing();
}

.export-content {
  // Reduce line height to condense content
  line-height: 1.142867143em; // 16px/14px (base font size)

  // Some places in the EHR (e.g. meds/therapies in newborn immediate postpartum
  // exams) use two nested `.indented` classes instead of `.double-indented` for
  // the label before sub-items that are then indented with `.double-indented`
  // instead of the usual `.indented`. Since this stylesheet doesn't define
  // `.double-indented`, the label would be indented further than the sub-items
  // (and unaligned with any sibling items) when rendered in the export, so
  // negate the indentation of the label (total `margin-left` of 0). Nested
  // indents are used without `.double-indented` in the labor flowsheet, so
  // limit this rule to inside `.form-group`.
  .form-group .indented > .indented {
    margin-left: -18px;
  }
}
