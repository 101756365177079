.input-glyph-group-right {
  position: relative;

  .form-control {
    padding-right: @padding-base-horizontal + @font-size-base + @padding-base-horizontal;
  }

  .input-glyph {
    position: absolute;
    top: 0; right: 0;
    vertical-align: middle;
    line-height: inherit;
    color: @text-muted;
    padding: @padding-base-vertical @padding-base-horizontal;
    pointer-events: none;
  }
}

.expand-target(@target-padding: 10px) {
  position: relative;
  cursor: pointer;
  &::after {
    content:'';
    position: absolute;
    top: -@target-padding; bottom: -@target-padding;
    left: -@target-padding; right: -@target-padding;
  }
}

.expand-target(@top-bottom-padding, @left-right-padding) {
  position: relative;
  cursor: pointer;
  &::after {
    content:'';
    position: absolute;
    top: -@top-bottom-padding; bottom: -@top-bottom-padding;
    left: -@left-right-padding; right: -@left-right-padding;
  }
}

// makes the default .expand-target available in markup,
// as well as configurable as a mixin
.expand-target {
  .expand-target();
}

// make font awesome glyphs in dropdown menus and vnavbars fixed width
.dropdown-menu .fa,
.vnavbar .fa {
  .fa-fw();
}

.faded {
  .opacity(0.65);
}
