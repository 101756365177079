@import '../common/swatch.less';

.signup-middle {
  h1, .h1 {
    font-size: 25px;
    font-weight: normal;
    color: @mn-gray-dark;
  }

  h2, .h2 {
    font-size: 25px;
    font-weight: normal;
    color: @mn-purple;

    button {
      margin-left: 5px;
    }
  }

  h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .get-started {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
    }

    .signup-people-img {
      max-width: 100%;
      height: auto;
      max-height: 350px;
      border-radius: 0px 0px 50px 50px;
      display: flex;
      margin-left: auto;
    }
  }

  .thank-you {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
    }

    .thank-you-person-img {
      max-width: 100%;
      height: auto;
      max-height: 450px;
      border-radius: 0px 0px 50px 50px;
      display: flex;
      margin-left: auto;
    }
  }

  .contact-info, .error-info {
    background-color: #f2dede;
    padding: 15px;
    font-size: 16px;
  }

  .radio, .checkbox {
    margin-top: 0;
  }

  hr {
    border-top: 1px solid @mn-gray-med;
  }
}
