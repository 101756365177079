@import (reference) '~bootstrap/less/bootstrap.less';
@import (reference) '~@maternity/kerbin/app/css/common/variables.less';
@import (reference) '~@maternity/kerbin/app/css/mn/variables.less';

.abnormal-result {
  .text-danger();
  font-weight: bold;
}

.lab-comment {
  white-space: pre-wrap;
  font-family: monospace;
}

.profile-test-hr {
  border-color: @mn-gray-med;
  margin: 35px -15px;
}

.diagnosis-delete {
  font-weight: bold;
}

.profile-test-delete {
  font-weight: bold;
  padding-bottom: 0;
  margin-top: -5px;
}
