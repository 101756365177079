// bootstrap overrides

.dropdown-menu.datepicker {
  width:220px;

  .unselectable {
    background-color: @mn-gray-light !important;
    & > span {
      color:@mn-gray-med;
    }
  }

  table {
    table-layout: fixed;
    height: 100%;
    width: 100%;

    .fa {
      // Override rule that makes icons fixed-width in dropdowns
      width: inherit;
    }

    .btn.btn-default {
      border-radius: 0;
      border:none;
      margin-right: 0px;
      box-shadow: none;
      &:active {
        box-shadow: none;
      }
    }

    tr.text-center {

      .btn.btn-default {
        background: @mn-teal;
        color:#fff;
      }
    }

    th {
      border:none;
    }

    th.dow {
      background: @mn-gray-light;
      color:@mn-gray-med;
      padding:3px;
      font-weight: normal;
    }

    td {
      border:none;

      .btn.btn-default {
        background: transparent;
        padding:3px;
        color: #111;
        width: 100%;
      }

      .btn.btn-today {
        border: 1px solid @mn-gray-med;
      }

      .btn span {
        color: @mn-teal;
        &.text-muted {
          // hack bc .text-muted isn't strong enough
          color: #979797;
        }
      }

      .btn.btn-default.btn-primary {
        background: @mn-teal;
        span {
          color: @mn-white;
        }
      }
    }
  }
}

