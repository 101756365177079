@loading-image: '~@maternity/kerbin/app/images/icons/loading.gif';

[mun-spinner].mun-loading {
  &.mun-spinner-replace > * {
    visibility: hidden;
  }

  &::before {
    display: block;
    content: " ";
    margin: auto;
    padding: 2em;
    height: 90px;
    background: url('@{loading-image}') no-repeat 50%;
    text-align: center;
  }
}

mun-spinner > .mun-loading {
  content: " ";
  margin: auto;
  padding: 2em;
  height: 90px;
  background: url('@{loading-image}') no-repeat 50%;
  text-align: center;
}
