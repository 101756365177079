@import (reference) '~bootstrap/less/mixins/text-overflow.less';

mun-cptcode-search {
  display: inline-block;
  vertical-align: top;

  & > .dropdown {
    & > .dropdown-toggle {
      text-align: left;
    }

    & > .dropdown-menu {
      min-width: 100%;

      & > .dropdown-header {
        padding-right: 2em;
      }
    }
  }
}

mun-cptcode-refiner {
  & .mun-cptcode-row {
    cursor: pointer;
  }
}

.btn.mun-cptcode {
  .text-overflow();
}
