.activity {
  kerbin-avatar, .kerbin-avatar {
    margin-right: 10px;
  }
  .fa {
    margin-right: 3px;
  }
  .btn-primary {
    margin: 25px auto 0 auto;
    display: block;
  }
}

a.activity-link, a.activity-link:hover {
  color: inherit;
  text-decoration: none;
  display: block;
}

.activity-action {
  font-weight: 700;
}

.activity-date {
  margin: 0 3px;
}

.activity-provider-column,
.activity-client-column {
  min-height: 65px;
}

.activity-provider-column {
  .activity-primary {
    color: @mn-teal;
  }
}

.activity-client-column {
  .activity-primary {
    color:@mn-yellow;
  }
  @media (min-width: @screen-sm-min) {
    border-left: 1px solid @mn-gray-light;
  }
}

.no-activity {
  @media (max-width: @screen-xs-max) {
    display: none;
  }
}
