@import (reference) '~bootstrap/less/bootstrap.less';
@import (reference) '~@maternity/kerbin/app/css/common/variables.less';
@import (reference) '~@maternity/kerbin/app/css/mn/variables.less';
@import (inline) '~fullcalendar/dist/fullcalendar.css';

@import 'appointments';


.fc-view td {
  background: transparent;
}

.fc-bg {
  background: #FFF;
}

.fc-widget-header {
  background: transparent;
}

td.fc-widget-header {
  border-color: transparent;
}

.fc-day-header {
  color: black;
}

th.fc-day-header, th.fc-axis, .fc-row.fc-widget-header {
  border-color: transparent;
}

td.fc-axis.fc-time {
  background: #F7F6F2;
  border-color: transparent;
}

.fc-view.fc-agenda-view > table > tbody > tr > td.fc-widget-content {
  border-color: transparent;
}

.calendar-list-item {
  cursor: pointer;
}

.fc-event, .fc-event .fc-content {
  cursor: pointer;
}

.fc-now-indicator {
    border-color: @mn-gray-dark;
}

.inline-wkdays {
  .checkbox-inline, .checkbox {
    padding: 0px;
  }
}

.calendar-btn-group {
  .btn:not(:first-of-type) {
    border-left: 1px solid @btn-default-border;
  }
}
