// General layout rules go here.

html {
  min-height: 100vh;
  position: relative;
}

body {
  // Forces vertical scroll bar to always appear to prevent jumping
  overflow-y: scroll;
  @media @md-max {
    overflow-x: hidden;
  }
}

#middle {
  min-height: 100vh;
  padding-bottom: @mn-footer-height;
  padding-top: 0;
  @media @md-min {
    padding-top: @navbar-height + @navbar-margin-bottom;
  }
}

#sidebar {
  min-height:0px;
  position:relative;
  text-align: left;
}

#sidebar ~ #middle {
  @media @md-min {
    margin-left: @vnavbar-width;
  }
}

footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

// prevents off-screen scrollability for small screens
// when content extends beyond viewport
div[ng-app='kerbin-authstrap'] > div {
  overflow-x: hidden;
}
