// Dropdown enhancements

.dropdown-menu > li > .dropdown-text {
  .dropdown-header();
  font-size: @font-size-base;
  color: @text-color;
}

.dropdown-menu[dropdown-menu-fit] {
  overflow-y: auto;
}

// bootstrap overrides

.dropdown-menu {
  margin-top: 0px;

  &.dropdown-menu-expand {
    width: 100%;
  }

  li {
    padding:0;

    a {
      white-space: normal;
    }

    &:hover {
      background: transparent;
    }
  }

  .dropdown-header {
    // padding: 3px 20px;
    padding-top: 4px;   // +6px
    padding-left: 11px; // -9px
    font-size: 11px;
    letter-spacing: 1px;
  }

} // end overrides

.dropdown-right-align {
  left: auto;
  right: 0;
}

[dropdown-option], .dropdown-option {
  cursor: pointer;

  .dropdown-menu &:not(.focused) > a[href]:hover,
  .dropdown-menu &:not(.focused) > a[href]:focus {
    color: @text-color;
    background-color: @mn-practice-nav-color;
  }

  .dropdown-menu &.focused > a[href] {
    color: @mn-practice-nav-color;
    background-color: @mn-practice-nav-bg;
  }
}

.navbar-nav .open .dropdown-menu {
  @media @sm-max {
    position: absolute;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: #fff;
  }
}

.dropdown-menu li:not(.disabled) > a[href] {
  color: @text-color;

  &:hover,
  &:focus {
    color: @mn-practice-nav-color;
    background-color: @mn-practice-nav-bg;
  }
}

// A dropdown-toggle following a dropdown-query is rendered over the query
// when the dropdown is inactive.
[dropdown-query] ~ [dropdown-toggle] {
  position: absolute;
  top: 0;

  // When the dropdown is active or nothing is selected, show the query input.
  .dropdown.is-focused > &,
  .dropdown.ng-empty > & {
    display: none;
  }
}
