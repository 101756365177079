// Non-modal dialogs.

#dialogBar {
  position:fixed;
  right: 0;
  left: 0;
  bottom: 0;

  // Set appropriate z-index (dialogs should appear behind modal dialogs)
  z-index: (@zindex-modal-background - 1);

  // Allow pointer events to pass through
  // TODO: This requires a polyfill for IE < 11
  pointer-events: none;

  @media @sm-max {
    position:absolute;
    top: 10%;
    bottom: 15px;
  }
}

// .dialog should be applied to the element with .modal.
.dialog {
  // Set position:static, negating top/right/bottom/left/z-index
  position: static;
  // Hide scrollbar unless needed
  overflow-y: auto;

  float: right;
  margin-right: 20px;

  // Reset pointer events
  pointer-events: all;
  // TODO: copy transition from .modal?
  padding-bottom:55px;
}


#alertContainer {
  position:fixed;
  right:@padding-base-horizontal;
  top: @navbar-height + @navbar-margin-bottom + 2*@line-height-computed  + 2*@breadcrumb-padding-vertical;

  z-index: @zindex-modal;
}

