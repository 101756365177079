@import (reference) '~font-awesome/less/variables';

// We like vertically-centered table cells.
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}

// These styles got messy, so now they're quarantined behind mn-table-dontuse.
// Thus, they are deprecated; use them if you must, but be aware that we'll get
// rid of them in the future.
table.mn-table-dontuse {
  th {
    background: @mn-practice-nav-bg;
    color: #fff;
    border-right: 1px solid white;
    font-weight: 400;
  }

  thead tr td {
    background: lighten(@mn-purple, 10%);
  }

  td {
    background-color: #fff;
    border-bottom: 1px solid @mn-gray-light;
  }

  &.table {
    & > thead, & > tfoot {
      > tr > th {
        border:none;
      }
    }
  }
  td.sm,
  th.sm {
    width: 100px;
  }

  td.lg,
  th.lg {
    min-width: 0;
  }
}


// A tag for sorting things. Add .asc or .desc to make it active.
// Use .fa.sort-arrow to get one of the three sort arrows depending on the
// sort-header's class.
sort-header {
  i.fa.sort-arrow:before { content: @fa-var-sort; }
  &.asc, &.desc {
    text-decoration: underline;
  }
  &.asc i.fa.sort-arrow:before { content: @fa-var-sort-asc; }
  &.desc i.fa.sort-arrow:before { content: @fa-var-sort-desc; }
}

// Bootstrap has a random rule that all th's are left-aligned; this makes .text-
// center and .text-right not work well with tables.
th {
  text-align: inherit;
}

// Standard table styles all require .table
table.table {
  // For cells/rows that should fade out a bit
  .cell-muted {
    background: @mn-gray-light;
    color: @text-muted;
  }
  // For manual striping
  .cell-alt {
    background: @table-bg-accent;
  }
  // For cases where you want to put two things in one cell
  .cell-top-half {
    border-bottom: 1px solid @mn-border-gray-lt;
  }


  // Item list style - for showing lists of items
  &.table-itemlist {
    // Header style: White with muted text
    .cell-heading {
      color: @text-muted;
      font-weight: bold;
    }
  }

  // Flowsheet style - for showing data over time
  // Usually combined with .text-center and .table-bordered
  &.table-flowsheet {
    .cell-heading {
      background: @mn-light-purple;
      td, th {
        border-left: none;
        border-right: none;
      }
    }
  }
}

// This is copied from bootstrap because we need it to work at other sizes
.table-responsive-mixin() {
  width: 100%;
  margin-bottom: (@line-height-computed * 0.75);
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  // Tighten up spacing
  > .table {
    margin-bottom: 0;

    // Ensure the content doesn't wrap
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          white-space: nowrap;
        }
      }
    }
  }

  // Special overrides for the bordered tables
  > .table-bordered {
    border: 0;

    // Nuke the appropriate borders so that the parent can handle them
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }
      }
    }

    // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
    // chances are there will be only one `tr` in a `thead` and that would
    // remove the border altogether.
    > tbody,
    > tfoot {
      > tr:last-child {
        > th,
        > td {
          border-bottom: 0;
        }
      }
    }
  }
}

// Trigger the table-responsive transformation at different sizes

.table-responsive-xxs {
  overflow-x: auto;
  @media @xxs-max {
    .table-responsive-mixin()
  }
}

.table-responsive-xs {
  overflow-x: auto;
  @media @xs-max {
    .table-responsive-mixin()
  }
}

.table-responsive-sm {
  overflow-x: auto;
  @media @sm-max {
    .table-responsive-mixin()
  }
}

.table-responsive-md {
  overflow-x: auto;
  @media @md-max {
    .table-responsive-mixin()
  }
}

.table-responsive-lg {
  overflow-x: auto;
  .table-responsive-mixin()
}
