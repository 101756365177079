// NAV PRACTICE

#practice-nav {

  .vnavbar {
    width: inherit;
    margin: 0;
    .vnavbar-nav {
      width: @vnavbar-width;
    }
  }
  .dropdown-menu {
    padding:0;
  }

  .mn-nav-practice {
    @media @sm-max {
      margin: 0;
      float: left;
      padding-right:5px; // reduces default padding on small screens
    }
  }

  .mn-nav-practice > .dropdown > .dropdown-toggle {
    background: @mn-practice-nav-bg;
    border-right: solid 1px @mn-practice-nav-bg;

    @media @xs-max {
      background: inherit; // overrides hover bg on touch devices
      border-right: none;
      padding-right: 0px;
    }


    &:hover {
      background: lighten(@mn-practice-nav-bg, 10%);
    }

    &:focus {
      background-color: lighten(@mn-practice-nav-bg, 15%);
      @media @xs-max {
        background-color:inherit;
      }
    }
  }

  .mn-nav-practice > .dropdown > .dropdown-toggle {
    padding: 0 20px;
    transition: all 0.2s linear;

    i {
      font-size: 20px;
      .navbar-vertical-align(20px);

      &.fa-caret-down {
        @media @xs-max {
          display:none; // hides arrow
        }
      }
    }
  }

  .practice-nav-item,
  .vnavbar-nav-item {
    position: relative;
    & > a {
      border-color: @mn-gray-light;
      margin-bottom: -1px;
      color: @mn-gray-med;
      &:hover {
        background: darken(@mn-white,10%);
      }
    }
    &.active {
      & > a {
        background: darken(@mn-white,10%);
      }
    }
    .vnavbar-nested-summary {
      border-color: @mn-gray-light;
      background: @mn-white;
      &:hover {
        background: darken(@mn-white,10%);
      }

      &::before {
        color: @mn-gray-med;
      }
    }
    .nested-nav-list {
      padding-top: 0px;
      padding-bottom: 0px;
      & > li & > a {
        border-top: 1px solid;
        border-color: @mn-gray-light;
        padding-left: 35px;
        color: @mn-gray-med;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  // override ultra-specific bootstrap dropdown styling (for practice-menu only).

  .mn-nav-practice.navbar-nav .open .dropdown-menu > li > a {
    padding: 10px 20px;
  }
}
