@import (reference) '~bootstrap/less/bootstrap.less';
@import (reference) '~@maternity/kerbin/app/css/common/variables.less';
@import (reference) '~@maternity/kerbin/app/css/mn/variables.less';


.patient-table-styling () {
  .table-responsive {
    overflow-y: visible;
  }
  .border() {
    border: 1px solid #dddddd;
  }
  table {
    border-collapse: collapse;
    border: none;
  }
  thead {
  }
  tbody {
    border-collapse: collapse;
  }
  .row-border {
    tr{
      .border();
    }
  }
  td, th {
    border: none;
    vertical-align: middle;
  }

  td {
    background-color: transparent;
  }
}


@import 'core.less';
@import 'insurance.less';
@import 'procedures.less';
@import 'claims.less';
@import 'payments.less';
@import 'fees.less';
@import 'payers.less';
