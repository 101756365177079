.comment-body {
  // Preserve whitespace and line breaks in comment bodies
  white-space: pre-wrap;
}

.select2-drop .accent {
  font-weight: bold;
}


// Use for hiding a file input without actually removing it from the layout.
// Keeping it in the layout is required by some browsers, and also means it's
// tab-selectable, detected by screen-readers, etc.
//
// Typical use is in a label (so that clicks activate the hidden element):
//
// label.btn.btn-primary(role="button") Choose a file
//   input.hidden-file-input(type="file")
//
// Sadly, there doesn't seem to be a way for a focus on the input to affect the
// parent label (e.g. when you tab-select it).
input.hidden-file-input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
}

// Private Status

.status {
  background:#ea6b53;

   p {
    color: #fff;
    padding: 5px 10px;
    margin: 0;
    text-align: center;
  }
}

.message-list {
  td.sm .fa {
    // options icons
    margin: 0 5px;
    font-size: 1.5em;
  }
  .title-col {
    min-width: 400px;
    max-width: 400px;
    @media @sm-max {
      max-width: 100%;
      min-width: 100%;
      h4 {
        max-width: 85%;
        overflow: hidden;
      }
    }
  }
  .comments-col {
    max-width: 230px;
    @media @sm-max {
      max-width: 100%;
    }
  }
  .toggle-all-fields {
    top:20px;
  }
}

.message-list-header-menu {
  @media @xs-max {
    margin-top: 10px;
    input, select {
      margin-bottom: 5px;
    }
  }
}
