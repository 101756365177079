// Dropdown stylings unique unique to user-nav-menu


#practice-nav {
  .mn-nav-user {
    border-left: solid 1px lighten(@mn-practice-nav-bg, 15%);
    transition: all 0.2s linear;

    @media @sm-max {
      float:right;
      margin: 0 -6px 0;
      border: none;
    }

    .dropdown-menu {
      min-width: 100%;
      @media @sm-max {
        right: 0;
        left: auto;
        // margin: 6px 0 0!important;
      }
    }

    .mn-name {
      display: inline-block;
      @media @sm-max {
        display: none;
      }
    }

    .fa-caret-down {
      padding-left: 3px;
    }

    &:hover {
      background-color: #6c5e88;
    }

    &:focus {
      background-color: lighten(@mn-practice-nav-bg, 5%);
    }

    // override default bootstrap padding on small screens (only for user-menu)
    &.navbar-nav > li > a {
      @media @sm-max {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
}
