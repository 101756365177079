// general styles for navbar and containers

#practice-nav {
  position:static; // default, for small screens
  top: 0;
  right: 0;
  left: 0;
  border: 0;
  padding:0;
  z-index: 10; // ensures overlapping of sidebar

  @media @md-min {
    position:fixed;
  }

  .navbar-collapse {
    padding:0;
    border:none;
    box-shadow: none;
    -webkit-box-shadow:none;
  }
  .container-fluid {
    @media @sm-max {
      padding-right:0;
    }
  }
  &, .navbar-text {
    color: @mn-practice-nav-color;
    background: @mn-practice-nav-bg;
  }

  .navbar-brand {
    color: white;
    font-size: 18px;
    font-weight: 600;

    @media @sm-max {
      font-size: 14px;
      padding-left: 15px;
    }

    .navbar-link {
      color: @mn-practice-nav-color;
    }
    .popover-title {
      color: @mn-gray-dark;
    }
  }

  .mn-nav-practice ~ .navbar-brand { // excludes client views, which has no mn-nav-practice
    @media @sm-max {
      padding-left: 0px;
    }
  }


  .support-link {
    margin-right: 15px;
    color: @mn-white;
    background: transparent;
    @media @md-max {
      float:right;
    }
  }
}

// No horizontal padding at navbar edges (the outermost component already has padding)
.navbar > .container,
.navbar > .container-fluid {
  padding-left: 0;

  & > .navbar-header,
  & > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;

    & > .navbar-brand {
      margin-left: 0;
    }
  }
}


.mn-nav-new.navbar-btn {
  @media @sm-max {
    display: inline-block;
    margin-bottom: 0;
    float: right;
  }

}
