.button-dropdown {
  button.dropdown-toggle {
    width: 100%;
    .pull-right {
      i.fa {
        margin-left: 5px;
      }
    }
  }
  ul.dropdown-menu {
    text-align: left;
    width: 100%;
    min-width: 0px;
    right: 0;
    left: auto;
    li {
      a {
        padding: @padding-base-vertical @padding-base-horizontal;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

#eve, #eve-practice {
  #eve-main {
    overflow: visible;
    padding: 20px;
  }
}
