// bootstrap overrides

.nav-tabs {
  border:none;

  > li.active > a, > li.active > a:hover, > li.active > a:focus, > li a:hover {
    color: @text-color;
    background-color: #fff;
    border:none;
    cursor: default;
  }

  > li {
    float: left;
    margin-bottom: -2px;
    margin-right: 4px;

    > a {
      font-size: 15px;
      font-weight: 600;
      padding: 8px 25px;
      border-radius: 0;
      color:#8D8C8B;
      border:none;
      transition: all 0.2s linear;
    }

    a:hover {
      background: transparent;
    }
  }
}

// end overrides
