/*
Generic Styling, for Desktops/Laptops
*/
table.mn-table-dontuse.responsive-data-table {
  width: 100%;
  border-collapse: collapse;
  tbody > tr > th.active > span {
    text-decoration: underline;
  }
  th {
    background: @mn-white;
    color: @mn-gray-dark;
    font-weight: bold;
    padding-bottom: 10px;
    & > span {
      cursor: pointer;
    }
  }
  td, th {
    padding: 10px;
    border-bottom: 1px solid @mn-gray-light;
    text-align: left;
    &.text-center {
      text-align: center;
    }
  }
  .toggle-all-fields {
    // configure top separately
    right: 20px;
    .expand-target(20px);
    position: absolute;
    color: @mn-gray-med;
    cursor: pointer;
  }
  td.text-overflow {
    @media @sm-max  {
      white-space: normal;
      padding-right: 22px;
    }
  }
}

@media @sm-max  {
  table.mn-table-dontuse.responsive-data-table {
    /* Force table to not be like tables anymore */
    table-layout: fixed; // Don't overflow parent container
    table, thead, tbody, th, td, tr {
      display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    tbody tr:first-of-type {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr { border-bottom: 1px solid @mn-gray-light; }

    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      &:not(:first-of-type) {
        padding: 3px 10px;
        text-align: left;
        &:last-of-type {
          padding-bottom: 10px;
        }
      & label {
        font-weight: bold;
        margin-right: 3px;
      }
      }
    }
    .sm {
      width: 100%;
    }
  }
}
