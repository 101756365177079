// Markup for all main screen headers should follow this pattern:

/*
header.main-screen-header
  h1
    i.fa.fa-file
    span  Files
    .main-screen-header-menu
      button.add-btn.btn.btn-primary(sref='"./add"')
        i.fa.fa-plus
        |  Add a file


If enhancements or overrides need to be made for individual screens
add one layer of specificity. ex:

.files-screen {
  .main-screen-header-menu {
    // custom styles for files screen
  }
}
*/

.main-screen-header {
  margin: 20px 0 20px 0;

  h1 {
    font-size: 25px;
    font-weight: normal;
    margin: 0 !important;
    color: @mn-gray-dark;

    label {
      font-size: @font-size-base;
    }

    .main-screen-header-crumbs {
      display: inline-block;
    }

    .main-screen-header-icon {
      margin-right: 10px;
    }
    .main-screen-header-label {
      color: @mn-gray-dark;
      margin-right: 20px;
    }
  }
}
.main-screen-header-menu {
  float: none;
  margin-top: 10px;

  @media @sm-min {
    float: right;
    margin-top: 0px;
  }

  .btn-group .btn {
    margin-right: 0;
  }

  //  TODO: Standardize how things get into the header menu
  > *, main-header-menu > *, .main-header-menu > * {
    // Use float so that there is no additonal margin when divs havs an
    // indentation between them
    float: left;
    margin-right: 10px;
    @media @xxs-max {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
