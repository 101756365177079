.select2-result-single {
  padding-left: 0px;
}

.ui-select-container .close {
  right: auto;
  top: auto;
}

.ui-select-container .btn-default {
  background-color: #fff;
  box-shadow: none;
}
