// bootstrap overrides

@media (min-width: @screen-sm-min) {
  .form-horizontal {

    .control-label {
      text-align: left;
    }
  }
}

.form-horizontal.label-right {
   .control-label {
      text-align: right;

      @media @xs-max {
        text-align:left;
      }
    }
}

.navbar-form .form-control {
  border: none;
}

.form-control {
  .placeholder(#adadad);
}

.form-control:focus {
  border-color: lighten(@mn-teal, 10%);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(56, 166, 166, .6);
}

// Set width of select2
.select2-container {
  width: 100%;
}

.select2-drop-active {
  border: 1px solid #4ec3c3;
  border-top: none;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(56, 166, 166, 0.6);
}

.select2-choices, .select2-input {
  // outline: none!important;
  border: inherit;
}

// end overrides


// Mask values of sensitive fields until they receive focus
.input-mask {
  color: transparent;
  #gradient > .striped(#eee);
  background-size: 40px 40px;

  // Restore properties on focus
  &:focus {
    color: inherit;
    background-image: none;
  }
}
