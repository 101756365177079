

#practice-nav {
  .dropdown-toggle:not(.btn-primary) {
    color: @mn-practice-nav-color;
    background-color: transparent;
    font-size: 14px;

    kerbin-avatar img, .kerbin-avatar {
      // Cancel dropdown-toggle's big vertical margins.  Those are based on line-height, but the
      // user icon can stick out a little more.  The text will still have the same margins, so
      // overall layout won't change as long as the image height < navbar height.
      margin: -@navbar-padding-vertical 3px -@navbar-padding-vertical 0;
      // Leave 1*@navbar-padding-vertical
      max-height: @navbar-height - @navbar-padding-vertical;
      width: auto;
      // border: solid 2px lighten(@mn-practice-nav-bg, 10%);
    }
  }

  .navbar-btn.dropdown > .dropdown-menu {
    // Space for triangle
    margin-top: @font-size-base - @padding-base-vertical;
    // Position edges relative to button, to get a centered dropdown.  Unfortunately this requires a
    // fixed width.
    left: -5em; right: -5em;
  }
}
