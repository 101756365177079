#eve {
  #payments {
    .button-align-text {
      margin: @padding-base-vertical 0px;
    }
    @align-amount: right;
    .patient-table-styling();
    tr {
      &.balance-row {
        background: lighten(@mn-gray-light, 8%);
      }
    }
    td, th {
      &.total-and-balance {
        text-align: @align-amount;
        opacity: 0.7;
      }
      &.balance-owed {
        text-align: @align-amount;
        font-weight: bold;
        font-size: 1.5em;
      }
      &.amount {
        text-align: @align-amount;
        width:9%
      }
    }
    .ledger-note {
      textarea {
        margin-bottom: 10px;
      }
      i.fa {
        margin-right: 5px;
      }
    }
    .row-border tr {
      border-width:0 0 1px 0;
    }
  }
}
