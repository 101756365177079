// bootstrap overrides

.alert {
  border: none;
  font-size: 1.2em;
  padding:25px 35px;
  box-shadow: -5px 5px 6px 5px rgba(50, 50, 50, 0.30);
  outline: none;
}

.alert-success {
  background: #fff;
  border-left: 6px solid @mn-teal;
  border-right: 6px solid @mn-teal;

  .alert-link {
    color: @mn-teal;
  }
}

.alert-info {
  background: #fff;
  border-left: 6px solid @mn-purple;
  border-right: 6px solid @mn-purple;

  .alert-link {
    color: @mn-teal;
  }
}

.alert-warning {
  background: #fff;
  border-left: 6px solid @mn-yellow;
  border-right: 6px solid @mn-yellow;

  .alert-link {
    color: @mn-teal;
  }
}

.alert-danger {
  background: #fff;
  border-left: 6px solid @mn-orange;
  border-right: 6px solid @mn-orange;

  .alert-link {
    color: @mn-teal;
  }
}
// bootstrap overrides
