// Load all variables from kerbin
// TODO: Find a better way for this.
@import (reference) '~@maternity/kerbin/app/css/style.less';
@import 'index';
@import 'resource';
@import 'schedule';


@media (min-width: @screen-md-min) {
  // For content upload screen
  // Resort col-md-4 items into vertical columns
  .csscolumns .vcol-md-4 {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;

    & > .col-md-4 {
      width: 100%;
    }
  }
}
