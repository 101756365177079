// Styles for user-list
// imported by kearth (ehr.less)

.grid-list.row {
  // margin: 0 0 20px;

  h3 {
    margin: 15px 0 5px 0;
  }

  a:hover, a:active {
    text-decoration: none;
  }

  .item {
    margin: 5px 0 10px 0;
    padding: 5px 0px 2px 5px;
    float: left;
    transition: all 0.2s linear;

    &:hover, &:active {
      background: lighten(@mn-gray-light, 5%);
      transition: all 0.2s linear;
    }
  }

  a.media {
    display: block;
  }

  .media-body {
    h4 {
      margin: 2px;
      font-size: 15px
    }

    p {
      margin:2px 0 2px 0;
    }
  }
}
