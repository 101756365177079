.condensed-spacing() {
  .form-group {
    margin-bottom: 4px;
  }
  .panel {
    // Due to import order, the margin in `overrides/panels.less` would have
    // higher priority than this without `!important`
    margin-bottom: 0px !important;
  }
  .panel-heading {
    padding: 10px 25px;
  }
}

@media print {
  .condensed-spacing();

  // Use the `href-hidden-print` class to hide the href when printing.
  a.href-hidden-print[href]:after {
    content: "";
  }

  // Use this class to remove column widths and offsets when printing.
  .col-print-reset {
    width: 100%;
    right: auto;
    left: auto;
  }
}
