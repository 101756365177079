@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 479px) {
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 1199px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 767px) {
}
smart-question {
  display: block;
}
.fivept-scale .fivept-scale-cell {
  background-color: transparent;
  width: 20%;
}
.fivept-scale .fivept-scale-nopadding {
  padding: 0;
}
.fivept-scale .fivept-scale-label {
  cursor: pointer;
  font-weight: normal;
  margin: 0;
  padding: 5px;
  width: 100%;
}
.moho-past-pregs {
  display: flex;
  flex-wrap: wrap;
}
.moho-past-pregs-item {
  padding-right: 12px;
  flex-grow: 1;
}
