/* MN Avatar styles */

.avatar-default {
  height: 40px;
  width: 40px;
}

// TODO: will need to reconsider this, for different breakpoints
.avatar-sm {
  height: 35px;
  width: 35px;
}

.avatar-md {
  height: 50px;
  width: 50px;
}

.avatar-lg {
  height: 75px;
  width: 75px;
}
