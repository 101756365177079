#eve-practice {
  #fees {
    #save-error-container {
      &:focus, div:focus {
        outline: none;
      }
    }
    .edit-controls {
      button.pull-right {
        margin-left: 10px;
      }
    }
    #procedures {
      margin-top: 15px;
      a.edit-code{
        cursor: pointer;
      }
      table {
        border-spacing: 0px 1px;
      }
      .disabled-procedure {
        span, i, input {
          opacity: 0.6;
        }
      }
      tbody {
        border: none;
      }
      td, th {
        background-color: #FFFFFF;
        border: none;
        vertical-align: middle;
      }
      th {
        background: @mn-purple;
      }
      td.code, th.code {
        width: 15%;
      }
      td.description, th.description {
        width: 75%;
        span {
          margin-right: 5px;
        }
        .tooltip-inner {
          max-width: 400px;
        }
      }
      td.fee, th.fee {
        width: 10%;
        text-align: right;
        input {
          text-align: right;
          min-width: 85px;
        }
      }
      .edit-procedures {
        td.enable-procedure, th.enable-procedure {
          width: 5%;
          text-align: center;
        }
        td.description, th.description {
          width: 70%;
        }
      }
      tr {
        border-bottom: 1px solid @mn-border-gray-lt;
      }
      td.code-group {
        cursor: pointer;
        background-color: @mn-purple;
        color: #FFFFFF;
        h3, h4, h5, h5 {
          margin: 0px;
        }
        i {
          margin-right: 5px;
        }
      }
      .collapse.in {
        display: table-row-group;
      }
    }
  }
}
