//== Buttons

// bootstrap variables
@btn-font-weight:         400;
@btn-default-bg:          @mn-gray-light;
@btn-link-disabled-color: @mn-gray-dark;

// bootstrap overrides
.btn {
  // We create the illusion of depth by adding a 2px box shadow. It shrinks on
  // click, and is missing entirely on link btns and disabled btns.
  box-shadow: 0px 2px 2px 0px #888;

  &:active {
    box-shadow: 0px 1px 1px 0px #888;
  }

  &:disabled, &[disabled], &.btn-link{
    box-shadow: none;
  }

  // Transitions, except when animating.
  transition: background-color 0.1s linear, box-shadow 0.1s linear, border-color 0.1s linear;
  &.ng-enter, &.ng-leave, &.ng-animate {
    transition: none;
  }
  // Hide focus glow
  &:focus, &.focus, &:active, &:active:focus, &:active.focus {
    outline: none;
  }

}

// New button type for outline button; no box shadow
.btn-outline {
  .button-variant(@text-color; rgba(0,0,0,0); @btn-default-border);
  box-shadow: none;

  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}
