.duna--resource {
  .article-img-container {
    display: table;
    float: right;
    clear: both;
  }

  .article-img-clickable {
    cursor: pointer;
  }

  .article-img-caption {
    display: table-caption;
    caption-side: bottom;
  }

  .contributor-icon {
    max-width:100%;
  }
  .resource-summary {
    margin-top: 30px;
  }
}

.duna--resource-html {
  font-size: 15px;

  small, .small {
    font-size: 80%;
    .text-muted();
  }
}

.duna--resource-thm { // thumbnail
  @media @xxs-max {
    width: 100%;
    text-align: center;
    float: none !important; // disable pull (which uses !important)

    .media-object {
      display: inline-block;
    }
  }
}



