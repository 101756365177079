// When the grid won't work, but you want a responsive image...
.img-responsive-sm {
  max-width: 35px;
  height: auto;
  @media @md-min {
    max-width: 50px;
  }
  @media @lg-min {
    max-width: 70px;
  }
}